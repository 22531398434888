












































import BaseButton from '@/components/base/BaseButton.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import ScheduleSelectInput from '@/components/inputs/ScheduleSelect/ScheduleSelectInput.vue';
import ScheduleCalendar from '@/components/SlotCalendar/ScheduleCalendar.vue';
import { useAuthStore } from '@/composables/useAuthStore';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { Schedule } from '@/models/booked/Schedule';
import { defineComponent, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import InlineLegend from '../BookSpace/ScheduleCalendar/components/InlineLegend.vue';
import HomePageText from './components/HomePageText.vue';

export default defineComponent({
  components: { ScheduleSelectInput, HomePageText, BaseButton, DatePicker, ScheduleCalendar, MemberPage, InlineLegend },
  name: 'HomePage',
  setup() {
    const startDate = ref(new Date());
    const schedule = ref<Schedule | null>(null);
    const { isAuthed } = useAuthStore();

    const { router } = useRouter();

    const goToLogin = () => {
      router.push({ name: routeNames.auth.login });
    };

    return {
      startDate,
      schedule,
      goToLogin,
      isAuthed,
    };
  },
});
